import { Divider } from "antd";
import BulletHoleList from "../components/BulletHoleList";

import { CoreCompetencies } from "../data/core";
import { PastExperienceHeading, PastExperience } from "../data/core";
import { PertinentCodes } from "../data/core";

const AboutPage = () => {
    return <div>
        <h1 style={{ fontSize: '32px', fontFamily: 'BlackOpsOne-Regular', margin: '0px 0px 10px 0px' }}>About Delta</h1>

        <BulletHoleList
            title="Core Competencies"
            items={CoreCompetencies} />
        <Divider />
        <BulletHoleList
            title="Past Performance"
            subtitle={PastExperienceHeading}
            items={PastExperience}
            getText={item => item.name}
        />
        <Divider />
        <BulletHoleList
            title="Pertinent Codes"
            items={PertinentCodes}
        />
    </div >
};

export default AboutPage;