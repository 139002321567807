import { Carousel, Image } from 'antd';

const ImageContainer = ({ image, height }) => {
    return <div>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <Image src={image} alt="Heli" height={height}/>
        </div>
    </div>;
};

const ImageScroller = ({ images, height }) => {
    return <div>
        <Carousel autoplay autoplaySpeed={3000}>
            {
                images.map((x, idx) => <ImageContainer image={x} key={idx} height={height} />)
            }
        </Carousel >
    </div >;
};

export default ImageScroller;