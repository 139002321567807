import { Button, Tooltip, message } from "antd";
import { CopyOutlined } from '@ant-design/icons'

const CopyMessage = (text) => {
    navigator.clipboard.writeText(text);
    window.clipboardData.setData(text);
    message.info('Copied to Clipboard');
};

const CopyToClipboardButton = (props) => {
    return <div>
        <Tooltip title="Copy to Clipboard">
            <Button type="secondary" shape="square" icon={<CopyOutlined />} onClick={() => CopyMessage(props.text)}/>
        </Tooltip>
    </div>
};

export default CopyToClipboardButton;