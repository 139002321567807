export const CoreCompetencies = [
    "Delta Products and Development Corp is a manufacturer of Electrical/Mechanical devices used for military purposes since 1988.",
    "We maintain compliance status to ISO 9001:2015",
    "We are a small, woman-owned business.",
    "We partner with a small CNC machine shop in Holland, MI to manufacture parts for artillery of various sizes and complexity which require CNC milling, CNC lathe work, and welding. This shop's core strengths are CAD designing and problem solving."
];

export const PastExperienceHeading = "Manufacturing Electrical/Mechanical devices used for military purposes including:";
export const PastExperience = [
    {
        name: "Helicopter grips for the UH1 and Cobra AH1",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "F-16 Falcon grip assembly",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "Safety switch for the Abrams M1A1 tank",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "Push-pull cable assembly for the engines of the Abrams M1A1 tank",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "Rectifiers for the Bradley personnel carrier",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "Breechblock Lever Mechanism for the 105 Howitzer pulled cannon",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "Various machined subcomponents for the 105 Howitzer pulled cannon",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    },
    {
        name: "Rammer for the M777 (155) Howitzer",
        image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
    }
];

export const PertinentCodes = [
    "NAICS: 335999 336992 332710 332613 332994 332722 and others",
    "DUNS: 12-135-1522",
    "CAGE CODE: 63825",
    "WOMAN/VETERAN-OWNED SMALL BUSINESS"
];
