import { Table } from 'antd';
import { Products } from '../data/products';

const columns = [
    {
        title: 'Item',
        dataIndex: 'item'
    },
    {
        title: 'NSN',
        dataIndex: 'nsn'
    },
    {
        title: 'Delta #',
        dataIndex: 'delta'
    },
    {
        title: 'OEM',
        dataIndex: 'oem'
    },
];

const Title = () => {
    return <h1 style={{ fontSize: '32px', fontFamily: 'BlackOpsOne-Regular', margin: '-15px 0px -10px -12px' }}>Product Offering</h1>

};

const ProductPage = () => {
    return <div>
        <Table
            title={Title}
            columns={columns}
            dataSource={Products}
            pagination={false}
        />
    </div>
};

export default ProductPage;