import { Descriptions } from "antd";

import { Name, Address1, Address2, Phone, Email } from "../data/contact";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

const ContactPage = () => {
    return <div>
        <Descriptions
            title={
                <h1 style={{ fontSize: '32px', fontFamily: 'BlackOpsOne-Regular', margin: '0px 0px -10px 0px' }}>Contact Us</h1>
            }
            bordered
            column={1}
        >
            <Descriptions.Item label="Owners">{Name}</Descriptions.Item>

            <Descriptions.Item label="Address">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <div>{Address1}</div>
                        <div>{Address2}</div>
                    </div>
                    <div style={{ float: 'right' }}>
                        <CopyToClipboardButton text={Address1 + " " + Address2} />
                    </div>
                </div>
            </Descriptions.Item>

            <Descriptions.Item label="Phone">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {Phone}
                    <div style={{ float: 'right' }}>
                        <CopyToClipboardButton text={Phone} />
                    </div>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {Email}
                    <div style={{ float: 'right' }}>
                        <CopyToClipboardButton text={Email} />
                    </div>
                </div>
            </Descriptions.Item>
        </Descriptions>
    </div>
};

export default ContactPage;