export const Products = [
    {
        item: "CIRCUIT CARD ASSEMBLY",
        nsn: "1680010645145",
        delta: "C-0525-1",
        oem: "A218962130-00"
    },
    {
        item: "GRIP ASSEMBLY, CONTROLLER",
        nsn: "1680001064546",
        delta: "C-0525-2",
        oem: "205001046-1"
    },
    {
        item: "CONTROL STICK, AIRCRAFT",
        nsn: "1680001165513",
        delta: "C-0525-4",
        oem: "204001334-13"
    },
    {
        item: "GRIP ASSEMBLY, CYCLI",
        nsn: "1680010249407",
        delta: "C-0525-6",
        oem: "214-001-346-7"
    },
    {
        item: "GRIP ASSEMBLY, CONTROLLER, AIRCRAFT",
        nsn: "1680012873437",
        delta: "C-0525-7",
        oem: "114ES273-6"
    },
    {
        item: "GRIP ASSEMBLY, CONTROLLER, AIRCRAFT",
        nsn: "1680013891289",
        delta: "C-0525-7",
        oem: "A218960961-00"
    },
    {
        item: "GRIP ASSEMBLY, CONTROLLER, AIRCRAFT",
        nsn: "1680010088123",
        delta: "C-0525-8",
        oem: "214001346-1"
    },
    {
        item: "GRIP ASSEMBLY, CONTROLLER, AIRCRAFT",
        nsn: "1680012509502",
        delta: "C-0525-9",
        oem: "16C1080-5"
    }
];