import { List } from 'antd';

import bulletHoleImage from '../images/bullet_hole.png';
import Crosshair from '../images/crosshair.png'

const RenderListItem = (item) => {
    return <List.Item>
        <div style={{ display: 'flex' }}>
            <List.Item.Meta
                style={{ margin: '0px -10px' }}
                avatar={<img src={Crosshair} width={16} alt="bullet hole" />}
            />
            <div style={{ alignSelf: 'center' }}>
                {item}
            </div>
        </div>
    </List.Item>
};

const BulletHoleList = ({ title, subtitle, items, getText = item => item }) => {
    return <div>
        <List
            size="small"
            header={
                <div>
                    <h2>{title}</h2>
                    {subtitle ? <h3>{subtitle}</h3> : <div></div>}
                </div>
            }
            bordered
            dataSource={items.map(getText)}
            renderItem={RenderListItem}
        />
    </div>
};

export default BulletHoleList;