import { Affix, Layout, Menu, Image } from 'antd';
import { NavLink, Outlet } from 'react-router-dom';
import ImageScroller from './components/ImageScroller';
import { Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Address1, Address2, Email, Phone } from './data/contact';
import { PrimaryColor, SecondaryColor } from './data/theme';

import Heli from './images/heli.jpg';
import Tank from './images/tank.jpg';
import Plane from './images/plane.jpg';

import Cobra from './images/cobra.jpeg';
import Falcon from './images/falcon.jpg';
import Abrams from './images/abrams.png';

import Delta from './images/delta.png';
import HeaderImage from './images/header.png';

/*

<h1 style={{
        fontFamily: 'BlackOpsOne-Regular',
        fontSize: 48,
        color: 'white',
        margin: '0px 10px'
      }}>
        Delta Products and Development Corp
      </h1>

*/

const PageTitle = () => {
  return <NavLink to="/">
    <Button type="link">
      <img src={HeaderImage} alt="Delta Products and Development Corp" style={{ width: '100%', verticalAlign: 'sub', margin: '20px 0px 0px 0px' }} />
    </Button>
  </NavLink>;
};

const MenuButtons = () => {
  return <div>
    <NavLink to="/">
      <Button type="link">
        <p style={{ color: 'white' }}>Home</p>
      </Button>
    </NavLink>

    <NavLink to="/products">
      <Button type="link">
        <p style={{ color: 'white' }}>Products</p>
      </Button>
    </NavLink>

    <NavLink to="/contact">
      <Button type="link">
        <p style={{ color: 'white' }}>Contact</p>
      </Button>
    </NavLink>

    <NavLink to="/about">
      <Button type="link">
        <p style={{ color: 'white' }}>About</p>
      </Button>
    </NavLink>
  </div>;
};

const MissionStatement = () => {
  return <p style={{
    fontFamily: 'BlackOpsOne-Regular',
    margin: '0px 25px'
  }}>
    SUPPORTING NATIONAL DEFENSE WITH EXCELLENCE
  </p>;
};

const FooterContact = () => {
  return <div>
    <div><h3 style={{ color: 'white' }}>Contact</h3></div>
    <div style={{ display: 'flex', margin: '-20px 0px -20px 0px' }}>
      <div style={{ margin: '0px 5px 0px 0px' }}>Phone:</div>
      <a href={"tel:" + Phone} style={{ color: '#FFFFFFA6' }}>{Phone}</a>
    </div>
    <div >
      <a href={"mailto:" + Email} style={{ color: '#FFFFFFA6' }}>
        <MailOutlined style={{ margin: '0px 5px 0px 0px' }} />
        {Email}
      </a>
    </div>
  </div>;
};

const FooterLogo = () => {
  return <Image src={Delta} alt="Delta Logo" height={100} />
};

const FooterLocation = () => {
  return <div>
    <div><h3 style={{ color: 'white' }}>Location</h3></div>
    <div style={{ display: 'flex', margin: '-20px 0px -20px 0px' }}>
      <div style={{ margin: '0px 5px 0px 0px' }}>Delta Products and Development Corp</div>
    </div>
    <div>
      <a href={"http://maps.google.com/?q=" + Address1 + " " + Address2}>
        <div style={{ margin: '0px 0px -20px 0px', color: '#FFFFFFA6' }}>{Address1 + ", " + Address2}</div>
      </a>
    </div>
  </div>;
};

const FooterPageLinks = () => {
  return <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px 0px 0px', height: '30px' }}>
    <NavLink to="/"><p style={{ color: 'white' }}>Home</p></NavLink>
    <p style={{ margin: '0px 10px' }}>|</p>
    <NavLink to="/products"><p style={{ color: 'white' }}>Products</p></NavLink>
    <p style={{ margin: '0px 10px' }}>|</p>
    <NavLink to="/contact"><p style={{ color: 'white' }}>Contact</p></NavLink>
    <p style={{ margin: '0px 10px' }}>|</p>
    <NavLink to="/about"><p style={{ color: 'white' }}>About</p></NavLink>
  </div>
};

const FooterCompanyName = () => {
  return <div style={{
    textAlign: 'center',
    margin: '10px 10px'
  }}>
    Delta Products and Development Corp ©2022
  </div>;
};

function App() {
  return (
    <Layout className="layout">
      <Affix offsetTop={0}>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ display: 'block', backgroundColor: PrimaryColor }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
            <div style={{ width: '60%' }}>
              <PageTitle />
            </div>
            <div>
              <MenuButtons />
            </div>
          </div>
        </Menu>

        <Menu
          theme="dark"
          mode="horizontal"
          style={{ backgroundColor: SecondaryColor }}
        >
          <MissionStatement />
        </Menu>
      </Affix>

      <ImageScroller images={[Falcon, Cobra, Abrams]} height={400} />

      <Layout
        style={{
          margin: '30px 30px',
          padding: '10px 10px',
          background: 'white'
        }}>
        <div>
          <Outlet />
        </div>
      </Layout>

      <Menu
        theme='dark'
        mode="horizontal"
        style={{ display: 'flex', justifyContent: 'space-evenly', backgroundColor: SecondaryColor }}
      >
        <FooterContact />
        <FooterLogo />
        <FooterLocation />
      </Menu>

      <Menu
        theme='dark'
        mode="horizontal"
        style={{ display: 'block', backgroundColor: PrimaryColor }}
      >
        <FooterPageLinks />
        <FooterCompanyName />
      </Menu>
    </Layout>
  );
}

export default App;
