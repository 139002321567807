import { Divider, Statistic, Card, Empty, Button, Carousel, Image } from "antd";
import { NavLink } from "react-router-dom";
import { BsGearFill } from 'react-icons/bs';
import { MdGppGood, MdOutlineLocalShipping } from 'react-icons/md';
import { PrimaryColor } from '../data/theme';

import ISO from "../images/iso_cert.png"

const CompanyStatistics = () => {
    return <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 30px' }}>
        <Statistic title="Orders Completed" value={197} prefix={<MdOutlineLocalShipping />} />
        <Statistic title="Parts Manufactured" value={40000} prefix={<BsGearFill />} />
        <Statistic title="Part Defect Rate" value={.5} prefix={<MdGppGood />} suffix="%" />
    </div>;
};

// <CompanyStatistics />

//        <Image style={{ alignSelf: 'center', margin: '0px 40px 0px 0px' }} src={Lathe} width="40%" resizeMode="contain" />


const AboutCompanySlice = () => {
    return <div style={{ display: 'flex', placeContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '60%', margin: '0px 0px 0px 10px' }}>
            <h2>Delta Products and Development Corp</h2>
            <p style={{ fontSize: 18, textAlign: 'justify' }}>
                We are a small business based out of Zeeland, Michigan that proudly supplies mechanical and electrical devices for national defense. Delta is a woman and veteran-owned company, and we have been delivering products since 1988. To see our core competencies, past performance, and pertinent codes, click below.
            </p>
            <Button type="primary" style={{ backgroundColor: PrimaryColor }}>
                <NavLink to="/about" >
                    Learn More
                </NavLink>
            </Button>
        </div>
        <div style={{ margin: '10px 10px 0px 0px' }}>
            <Image src={ISO} height={150} />
        </div>
    </div>;
};

/*
const ProductToCard = (product) => {
    return <div style={{ maxWidth: '20%', display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
        <Card
            cover={
                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                    <Image src={Lathe} />
                </div>
            }
        >
            <p style={{ textAlign: 'center' }}>{product.item}</p>
        </Card>
    </div>;
};
*/


/*
        <div style={{ width: '20%', margin: '0px 40px 0px 40px' }} >
            <Carousel autoplay autoplaySpeed={3000}>
                {Products.map(ProductToCard)}
            </Carousel>
        </div>
*/
const OurProductsSlice = () => {
    return <div style={{ display: 'flex' }}>
        <div style={{ width: '80%', margin: '0px 0px 0px 10px' }}>
            <div>
                <h2>Our Products</h2>
                <p style={{ fontSize: 18, textAlign: 'justify' }}>
                    We offer many products, each of which are identified by NSN and OEM numbers. Click below to see our full catalog of products.
                </p>

                <Button type="primary" style={{ backgroundColor: PrimaryColor }}>
                    <NavLink to="/products" >
                        Product Catalog
                    </NavLink>
                </Button>
            </div>
        </div>

    </div>;
};


//<Image style={{ alignSelf: 'center', flex: 1, margin: '0px 0px 0px 0px' }} src={Building} width="20%" resizeMode="contain" />

const ContactUsSlice = () => {
    return <div style={{ display: 'flex' }}>

        <div style={{ width: '80%', margin: '0px 0px 10px 10px' }}>
            <h2>Contact Us</h2>
            <p style={{ fontSize: 18, textAlign: 'justify' }}>
                For any questions, or to receive a quote, please contact us via email or by phone.
            </p>
            <Button type="primary" style={{ backgroundColor: PrimaryColor }}>
                <NavLink to="/contact" >
                    Contact Us
                </NavLink>
            </Button>
        </div>
    </div>;
};


const HomePage = () => {
    return <div>
        <AboutCompanySlice />
        <Divider />
        <OurProductsSlice />
        <Divider />
        <ContactUsSlice />
    </div>
};

export default HomePage;